var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", [
          _c("div", { staticClass: "plan-container flex justify-center" }, [
            _c(
              "video",
              {
                staticClass: "cus-size",
                attrs: { muted: "", loop: "", autoplay: "", loading: "lazy" },
                domProps: { muted: true },
              },
              [
                _c("source", {
                  attrs: {
                    src: _vm.video.previewUrl || _vm.video.originalUrl,
                    type: "video/mp4",
                  },
                }),
                _vm._v(
                  "\n          Your browser does not support the video tag.\n        "
                ),
              ]
            ),
          ]),
          !!_vm.video.user
            ? _c("div", { staticClass: "white mt3 flex items-center" }, [
                _vm._v(
                  "\n        Shot by\n        " +
                    _vm._s(_vm.video.user.name) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }