




















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ModalPreviewVideos extends Vue {
  @Prop() video!: string
}
