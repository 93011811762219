



























































































































































































































































































































































































































import ContentMixin from '@/mixins/ContentMixin'
import { Vue, Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import ModalPreviewImages from '@/components/Modals/ModalPreviewImages.vue'
import ModalPreviewVideos from '@/components/Modals/ModalPreviewVideos.vue'
import InfiniteImageList from '../Common/Images/InfiniteImageList.vue'
import AudiogramImagesList from '../Audiogram/AudiogramImagesList.vue'
import AudiogramVideosList from '../Audiogram/AudiogramVideosList.vue'
import { computed, reactive, ref, watch } from '@vue/composition-api'
import { openAiQueryKeys, useGetOpenAiContext } from '@/hooks/openAi'
import { Getter } from 'vuex-class'
import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'
import get from 'lodash.get'
import { videoQueryKeys } from '@/hooks/videos'
import { downloadNumber } from '@/Interfaces/StoreInterFaces/accountInterfaces/SignupInterface'
import ModalPlans from './ModalPlans.vue'
import { truncateStringAtWord } from '@/utils/misc'
import pexelsLogo from '@/assets/icons/pexels-icon.png'
import isEmpty from 'lodash.isempty'

@Component({
  components: {
    InfiniteImageList,
    ModalPreviewImages,
    ModalPreviewVideos,
    AudiogramImagesList,
    AudiogramVideosList,
    ModalPlans,
  },
  setup() {
    const openAiRes = ref('')
    const openAiParams = reactive({
      topic: '',
      max_tokens: 4000,
      temperature: 0.7,
      description: '',
      template: '',
      keywords: '',
      voiceTone: 'Default',
      writingStyle: 'Default',
      language: 'English',
    })
    const isEnabled = computed(() => !!openAiParams.template || !!openAiParams.topic)

    const { data: openAiData, isFetching: isOpenAiFetching } = useGetOpenAiContext(openAiParams, {
      enabled: isEnabled,
    })

    watch(openAiData, (curVal) => {
      openAiRes.value = curVal
    })

    return {
      openAiRes,
      openAiData,
      isOpenAiFetching,
      openAiParams,
    }
  },
})
export default class ModalContent extends Mixins(ContentMixin) {
  @Prop() scene!: any
  @Prop() context!: string
  @Prop() data!: any
  @Prop() isSowVideo!: boolean
  @Prop() source!: string

  @Getter networkId!: string
  @Getter networkType!: string

  bgDragColor: boolean = false

  searchQuery = ''
  aiSearchQuery = ''
  sceneAiText = ''
  showImagePreview = false
  showVideoPreview = false
  image!: any
  video!: any

  openAiData!: any
  openAiRes!: any
  openAiParams!: any
  isOpenAiFetching: boolean = false

  showAISceneText = false

  selectedVideoTab: string = 'PEXELS' // or 'MY_LIBRARY'

  uploadingImage = false
  showPlans = false
  selectedModel = 'flux-schnell'
  selectedOrientation = '1:1'
  imageModels = [
    { title: 'Flux', value: 'flux-schnell', free: 'yes' },
    { title: 'Essential', value: 'essential-v2', free: 'no' },
    { title: 'SDXL', value: 'stable-diffusion-xl', free: 'yes' },
  ]
  orientations = [
    { title: 'Landscape', width: '896', height: '512', aspect_ratio: '7:4' },
    { title: 'Square', width: '512', height: '512', aspect_ratio: '1:1' },
    { title: 'Portrait', width: '512', height: '896', aspect_ratio: '4:7' },
  ]

  get pexelLogo() {
    return pexelsLogo
  }

  get isFreeAccount() {
    return ['VIDEO_FREE'].includes(this.networkType)
  }

  get selectedSceneImage() {
    return get(this.scene, 'image', null)
  }

  get selectedSceneStarTime() {
    return get(this.scene, 'start_time', null)
  }

  get selectedSceneEndTime() {
    return get(this.scene, 'end_time', null)
  }

  get imageSearchLibraries() {
    return [
      {
        LibraryName: 'UNSPLASH',
        name: 'Unsplash',
      },
      {
        LibraryName: 'GOOGLE',
        name: 'Google',
      },
      {
        LibraryName: 'GETTY',
        name: 'Getty',
      },
      {
        LibraryName: 'PIXABAY',
        name: 'Pixabay',
      },
    ]
  }

  get videoSearchLibraries() {
    return [
      {
        LibraryName: 'PEXELS',
        name: 'Pexels',
      },
      {
        LibraryName: 'PIXABAY',
        name: 'Pixabay',
      },
    ]
  }

  get gifSearchLib() {
    return [
      {
        LibraryName: 'GIPHY',
        name: 'Giphy',
      },
      {
        LibraryName: 'TENOR',
        name: 'Tenor',
      },
      {
        LibraryName: 'TRENDING',
        name: 'Trending',
      },
    ]
  }

  get width() {
    return this.selectedOrientation == '7:4' ? 896 : 512
  }

  get height() {
    return this.selectedOrientation == '4:7' ? 896 : 512
  }

  mounted() {
    if (
      [
        'VIDEO_PRO',
        'VIDEO_BASIC',
        'ADORI_BASIC_MONTHLY',
        'ADORI_BASIC_YEARLY',
        'ADORI_PREMIUM_MONTHLY',
        'ADORI_PREMIUM_YEARLY',
        'ADORI_BUSINESS_MONTHLY',
        'ADORI_BUSINESS_YEARLY',
      ].includes(this.networkType) &&
      !isEmpty(this.scene)
    ) {
      this.generateStockAIPrompt()
    }
    //@ts-ignore
    this.$refs.libTextArea.focus()
  }

  clearSearchQuery() {
    this.searchQuery = ''
  }

  closeModal() {
    this.$emit('closeModal')
  }

  search() {
    this.searchText = this.searchQuery
    this.gifSearchText = this.searchQuery
    this.videoSearchText = this.searchQuery
    this.queryClient.setQueryData([openAiQueryKeys.CONTEXT, this.networkId, this.openAiParams], {
      context: null,
      result: this.searchQuery,
    })
  }

  viewImages(image: string) {
    this.showImagePreview = true
    this.image = image
  }
  viewVideos(video: string) {
    this.showVideoPreview = true
    this.video = video
  }
  loadVideo(video: any) {
    // if (this.isFreeAccount) {
    //   this.showPlans = true
    //   return
    // }
    this.$emit('load-video', {
      ...video,
      start_time: this.selectedSceneStarTime,
      end_time: this.selectedSceneEndTime,
      title: this.openAiData,
    })
  }

  selectAiImage(img: string) {
    this.source === 'TRANSCRIPT'
      ? this.$emit('load-image', {
          url: img,
          start_time: this.selectedSceneStarTime,
          end_time: this.selectedSceneEndTime,
          title: truncateStringAtWord(this.openAiData, 20),
        })
      : this.$emit('load-image', {
          url: img,
        })
  }

  loadImage(img: any) {
    this.$emit('load-image', {
      ...img,
      start_time: this.selectedSceneStarTime,
      end_time: this.selectedSceneEndTime,
      title: this.openAiData,
    })
  }

  enhancePrompt() {
    if (this.aiSearchQuery) {
      this.openAiParams.template = ''
      this.openAiParams.description = ''
      this.openAiParams.topic = `Original prompt: ${this.aiSearchQuery}
Template: "A [vivid adjective] scene depicting [core concept from original prompt], set in [specific location or environment]. The image features [2-3 key visual elements], showcasing [themes or concepts related to the original prompt]. In the foreground, [detailed description of a focal point or action]. The background includes [1-2 additional elements that add depth or context].
The artistic style blends [art movement or technique] with [another complementary style], reminiscent of [specific artist or art period]. The color palette consists of [2-3 key colors or color families], creating an atmosphere that is [emotional tone or mood].
[Sensory detail] adds to the ambiance, while [additional visual element] provides [contrast/balance/interest]. The overall composition conveys a sense of [abstract concept or feeling], capturing the essence of [original prompt or theme]."
Please enhance original prompt using template and make sure its within 1024 characters. Only give enhanced prompt as response`
    }
    this.$gtag.event('improve-prompt', {
      event_category: 'User Interaction',
      event_label: 'Improve image gen prompt',
    })
  }

  openStockTab() {
    this.currentContentTab = 'STOCK'
    setTimeout(() => {
      //@ts-ignore
      this.$refs.libTextArea.focus()
    }, 200)
  }
  openLibraryTab() {
    this.currentContentTab = 'LIB'
    setTimeout(() => {
      //@ts-ignore
      this.$refs.libTextArea.focus()
    }, 200)
  }
  openGenerateTab() {
    this.currentContentTab = 'GENERATE'
    setTimeout(() => {
      //@ts-ignore
      this.$refs.aiTextArea.focus()
      this.aiSearchQuery = this.searchQuery
    }, 200)
  }

  @Watch('openAiRes')
  handleOpenAiRes() {
    // if (this.context === 'AI_THUMBNAIL') {
    //   this.stabilityParams.prompt = this.openAiRes
    // }
    if (this.context === 'BLOG_TEXT') {
      if (this.currentContentTab === 'STOCK') {
        this.searchQuery = this.openAiRes.replace(/['"]/g, '')
        // this.stabilityParams.prompt = this.openAiRes
        this.aiSearchQuery = this.openAiRes.replace(/['"]/g, '')
        this.search()
      } else {
        this.aiSearchQuery = this.openAiRes.replace(/['"]/g, '')
      }
      //   else {
      //     this.stabilityParams.prompt = this.openAiRes
      //     this.aiSearchQuery = this.openAiRes.replace(/['"]/g, '')
      //   }
      this.showAISceneText = true
    }

    if (['SCENE_BLOG_IMAGE', 'MAIN_BLOG_IMAGE'].includes(this.context)) {
      this.aiSearchQuery = this.openAiRes
      this.searchAiImages()
    }
  }

  generateAIImage() {
    this.openAiParams.template = ''
    this.openAiParams.description = ''
    this.openAiParams.topic = `"""scene description: ${this.scene.text} """ \n Without any extra text in response, write a short image prompt in english for scene description. `
  }

  generateStockAIPrompt() {
    this.openAiParams.template = ''
    this.openAiParams.description = ''
    if (this.scene.prompt) {
      this.searchQuery = this.scene.prompt
      this.aiSearchQuery = this.scene.prompt
      this.search()
    } else {
      this.openAiParams.topic = `give one or two comma separated keywords that encapsulates the main visual elements and characteristics of the described scene. Scene_description=${this.scene.text}.  Give only keywords as response. Translate non-English keywords to English while maintaining cultural context. `
    }
  }

  searchAiImages() {
    this.showAISceneText = true
    // this.stabilityParams.id = null
    this.stabilityParams.prompt = truncateStringAtWord(this.aiSearchQuery)
    this.stabilityParams.aspect_ratio = this.selectedOrientation
    this.stabilityParams.model = this.selectedModel
    this.stabilityParams.width = this.width
    this.stabilityParams.height = this.height
    this.$gtag.event('ai-image', {
      event_category: 'User Interaction',
      event_label: 'Generate AI Image',
    })
  }

  async loadImageFile() {
    const files = this.$store.getters.selectedFile
    if (files) {
      this.uploadImages(files)
    }
  }

  async dropImageFiles(e: any) {
    this.bgDragColor = false
    const files = e.dataTransfer.files
    this.uploadImages(files)
  }

  imageUploader() {
    this.$store.dispatch('showFileUploader', {
      accept: 'image/*',
      onChange: this.loadImageFile,
      multiple: true,
    })
  }

  async uploadImages(files: any) {
    this.uploadingImage = true
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const type = get(file, 'type', '')

      if (!type.includes('image')) {
        this.$store.dispatch('pushNotification', {
          text: `${file.name} is unsupported format`,
          type: 'ERROR',
        })
        continue
      }
      if (file) {
        try {
          await AdoriService.uploadImage(this.networkId, file)
        } catch (error) {
          console.log(error)
        }
      }
    }
    this.resetImageGallery()
    this.uploadingImage = false
  }

  async dropVideoFile(e: any) {
    this.bgDragColor = false
    const files = e.dataTransfer.files
    this.uploadVideos(files)
  }

  bytesToMegaBytes(bytes: any) {
    return bytes / (1024 * 1024)
  }

  async uploadVideos(files: any) {
    this.uploadingImage = true
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const type = get(file, 'type', '')

      if (!type.includes('video/mp4')) {
        this.$store.dispatch('pushNotification', {
          text: `${file.name} is unsupported format`,
          type: 'ERROR',
        })
        continue
      }
      if (this.bytesToMegaBytes(file.size) > 60) {
        this.$store.dispatch('pushNotification', {
          text: `${file.name} is longer than 60MB`,
          type: 'ERROR',
        })
        continue
      }
      if (file) {
        try {
          await await AdoriServiceV6.createVideo(this.networkId, file)
        } catch (error) {
          console.log(error)
        }
      }
    }
    this.queryClient.invalidateQueries(videoQueryKeys.LIB_VIDEOS)
    this.uploadingImage = false
  }

  videoUploader() {
    this.$store.dispatch('showFileUploader', {
      accept: 'video/mp4',
      onChange: this.loadMp4,
      multiple: true,
    })
  }

  loadMp4() {
    // TODO: Later based on plan
    // let download = get(downloadNumber, `[${this.networkType}]`, downloadNumber.YOUTUBE_METERED)
    // if (this.bytesToMegaBytes(this.$store.getters.selectedFile.size) > download) {
    //   this.$store.dispatch('pushNotification', {
    //     text: 'Please enter file lesser than ' + download + 'MB',
    //     type: 'WARNING',
    //   })
    //   return
    // }
    const files = this.$store.getters.selectedFile
    if (files) {
      this.uploadVideos(files)
    }
  }
}
